import React, { useState, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Loader, ErrorFallback } from "smart-react";
import QRCode from "react-qr-code";
import { Input } from "@progress/kendo-react-inputs";
import { Card } from "@progress/kendo-react-layout";
import { getDeviceRegistrationStatus } from "../Services/ConfigurationService";
import "./Configurations.scss";
import { Label } from "@progress/kendo-react-labels";
import { groupBy } from "@progress/kendo-data-query";
import { ListView } from "@progress/kendo-react-listview";
import ConfigurationContentSlider from "../Components/ContentSlider/ConfigurationContentSlider";
import { SliderCard } from "smart-react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { listRFProfiles } from "../Services/ConfigurationService";
import { Pager } from "@progress/kendo-react-data-tools";
import {
  HeaderRender,
  ListViewItemRender,
} from "../Components/ListView/ListView";

/**
 * Configuration main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Configuration component.
 */
const Configuration = () => {
  const [devcod, setDevcod] = useState("");
  const [termID, setTermID] = useState("");
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [showQRModal, setShowQRModal] = useState(false);
  const [qrData, setQRData] = useState("");
  const [loading, setLoading] = useState(false);
  const [dialogueLoading, setDialogueLoading] = useState(false);
  const [mtfTermType, setMtfTermType] = useState({
    value: "handheld",
    text: "Handheld",
  });
  const [mtfDisplayWidth, setMtfDisplayWidth] = useState(20);
  const [mtfDisplayHeight, setMtfDisplayHeight] = useState(16);
  const [localeId, setLocaleId] = useState({});
  const [localeData, setLocaleData] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [pagerData, setPagerData] = React.useState({
    skip: 0,
    take: 50,
  });
  const [totalCount, setTotalCount] = useState(-1);

  /**
   * Function to handle changes in Locale_id dropdown.
   * @param e
   */
  const handleLocaleIdChange = (e) => {
    setLocaleId(e.value);
  };
  /**
   * Function to handle changes in MTF Terminal Type dropdown.
   * @param type
   */
  const handleMtfTermTypeChange = (type) => {
    setMtfTermType(type);
    // Update display dimensions based on the selected type
    if (type.value === "vehicle") {
      setMtfDisplayWidth(40);
      setMtfDisplayHeight(8);
    } else {
      setMtfDisplayWidth(20); // Default values for handheld
      setMtfDisplayHeight(16);
    }
  };
  /**
   * generate QR.
   * @param profile
   * @param seq_number
   */
  const generateQR = async (profile, seq_number = null) => {
    setShowQRModal(true);
    setDialogueLoading(true);
    // Extracting sequence number from auto_devcod_seqnum
    const sequenceNumber =
      seq_number != null ? seq_number : parseInt(profile.auto_devcod_seqnum);
    // Incrementing the sequence number by 1
    const nextSequenceNumber = sequenceNumber + 1;

    const numberPart = nextSequenceNumber.toString().padStart(7, "0");
    const generatedDevcod = `SM${numberPart}`; // Ensure 'SM' prefix followed by 7-digit number
    const generatedTermId = `SM${numberPart}`; // Ensure 'SM' prefix followed by 7-digit number
    const data = {
      profileSetupGUID: profile?.srfqrvm?.SRFProfilesSetupId,
      smartAppsURL: profile?.srfqrvm?.SmartAppURL,
      mocaURL: profile?.srfqrvm?.MOCAURL,
      mtfURL: profile?.srfqrvm?.MTFURL,
      devcod: generatedDevcod,
      termId: generatedTermId,
      mtfTermType: mtfTermType?.value,
      mtfDisplayWidth: mtfDisplayWidth,
      mtfDisplayhight: mtfDisplayHeight,
      localeId: localeId?.value, // Set Locale_id
      mtfVendorName: `${profile?.srfqrvm?.MTFVendorName}`, // Set Mtf_ven_nam
    };
    const jsonStr = JSON.stringify(data);
    const base64Str = btoa(jsonStr);
    setQRData(base64Str);
    setDevcod(generatedDevcod);
    setTermID(generatedTermId);
    setDialogueLoading(false);
  };
  /**
   * Handles editing an assistant.
   * @param profile
   */
  const handleGenerateQRCode = (profile) => {
    setSelectedProfile(profile);
    setLocaleData([
      { value: profile?.srfqrvm?.LocaleId, text: profile?.srfqrvm?.LocaleId },
    ]);
    setLocaleId({
      value: profile?.srfqrvm?.LocaleId,
      text: profile?.srfqrvm?.LocaleId,
    });
    generateQR(profile);
  };

  /**
   * check And Update QR.
   * @param profileId
   */
  const checkAndUpdateQR = async (profileId) => {
    if (selectedProfile) {
      try {
        const response = await getDeviceRegistrationStatus({
          profileSetupId: profileId,
        });
        const currentSeqNumber = parseInt(response?.Payload);
        const seqNumberFromTermID = parseInt(termID.slice(-2));
        if (
          seqNumberFromTermID < currentSeqNumber ||
          seqNumberFromTermID === currentSeqNumber
        ) {
          const nextSequenceNumber = currentSeqNumber + 1;
          const numberPart = nextSequenceNumber.toString().padStart(7, "0");
          const generatedDevcod = `SM${numberPart}`; // Ensure 'SM' prefix followed by 7-digit number
          const generatedTermId = `SM${numberPart}`; // Ensure 'SM' prefix followed by 7-digit number
          setDevcod(generatedDevcod);
          setTermID(generatedTermId);
          generateQR(selectedProfile, currentSeqNumber);
        }
      } catch (error) {
        console.error("Error checking device registration status:", error);
      }
    }
  };

  useEffect(() => {
    if (selectedProfile) {
      // Call the function initially
      checkAndUpdateQR(selectedProfile?.srfqrvm?.SRFProfilesSetupId);

      // Set interval to repeatedly check and update QR every 5 seconds
      const intervalId = setInterval(() => {
        checkAndUpdateQR(selectedProfile?.srfqrvm?.SRFProfilesSetupId);
      }, 5000);

      // Clear interval when component unmounts to prevent memory leaks
      return () => clearInterval(intervalId);
    }
  }, [selectedProfile]);

  useEffect(() => {
    if (selectedProfile) {
      generateQR(selectedProfile);
    }
  }, [mtfTermType, localeId]);

  /**
   * fetch Profiles Data.
   */
  const fetchProfileData = async () => {
    setLoading(true);
    try {
      const response = await listRFProfiles({
        limit: pagerData?.take,
        offset: pagerData?.skip,
        filter: [],
        sort: [],
      });
      const result = groupBy(response.profiles, [
        { field: "ProfileGroupName" },
      ]);
      setProfileData(result);
      setTotalCount(response?.TotalCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (e) => {
    setPagerData({
      skip: e.skip,
      take: e.take,
    });
  };

  useEffect(() => {
    fetchProfileData();
  }, [pagerData]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // Handle error boundary reset
      }}
    >
      {loading && <Loader />}
      <div className="configuration-container">
        <Card className="configuration-card">
          <div className="k-px-3 k-pb-10">
            {profileData?.map((profile, index) => (
              <ListView
                data={profile?.items}
                item={(props) => (
                  <ListViewItemRender
                    {...props}
                    handleGenerateQRCode={handleGenerateQRCode}
                  />
                )}
                header={() => <HeaderRender title={profile?.value} />}
              />
            ))}
            {parseInt(totalCount) > 50 && (
              <div>
                <Pager
                  className="k-listview-pager"
                  skip={pagerData?.skip}
                  take={pagerData?.take}
                  onPageChange={handlePageChange}
                  total={totalCount}
                />
              </div>
            )}
          </div>
        </Card>
      </div>
      <ConfigurationContentSlider show={showQRModal}>
        <SliderCard
          title={"Generate QR"}
          handleSlide={() => {
            setShowQRModal(false);
            setSelectedProfile(null);
          }}
          className="ai-slider"
        >
          <SliderCard.Body>
            {dialogueLoading && <Loader />}
            {qrData && (
              <div className="k-mt-5">
                <div className="k-text-center k-mb-3">
                  <QRCode value={qrData} size={256} viewBox={`0 0 256 256`} />
                </div>
                <hr />
                <Label>Terminal Type</Label>
                <DropDownList
                  data={[
                    { value: "handheld", text: "Handheld" },
                    { value: "vehicle", text: "Vehicle" },
                  ]}
                  value={mtfTermType}
                  textField="text"
                  dataItemKey="text"
                  className="!k-mb-3"
                  onChange={(e) => handleMtfTermTypeChange(e.target.value)}
                />
                <Label>Device Display Width:</Label>
                <Input
                  value={mtfDisplayWidth}
                  readOnly
                  className="!k-mb-3"
                  disabled // Non-editable for now
                />
                <Label>Device Display Height:</Label>
                <Input
                  value={mtfDisplayHeight}
                  readOnly
                  className="!k-mb-3"
                  disabled // Non-editable for now
                />
                {/* Dropdown for selecting Locale_id */}
                <Label>Locale ID</Label>
                <DropDownList
                  data={localeData} // Only one value for now
                  value={localeId}
                  textField="text"
                  dataItemKey="text"
                  onChange={handleLocaleIdChange}
                  className="!k-mb-3"
                />
                <Label>Devcod</Label>
                <Input
                  value={devcod}
                  onChange={(e) => {
                    setDevcod(e.target.value);
                    handleInputChange(e, "devcod");
                  }}
                  required={true}
                  className="!k-mb-3"
                  readOnly
                />
                <Label>Term ID</Label>
                <Input
                  value={termID}
                  onChange={(e) => {
                    setTermID(e.target.value);
                    handleInputChange(e, "termID");
                  }}
                  required={true}
                  className="!k-mb-3"
                  readOnly
                />
              </div>
            )}
          </SliderCard.Body>
        </SliderCard>
      </ConfigurationContentSlider>
    </ErrorBoundary>
  );
};
export default Configuration;
